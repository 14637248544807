import SelectType from '../../../share/form/type/SelectType'
import InputType from '../../../share/form/type/InputType'

export default {
  components: { InputType, SelectType },
  props: {
    displayData: {
      required: true
    },
    correction: {
      type: Boolean,
      default: () => false
    },
    invoiceNumber: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    dataToSend: {
      type: Array,
      required: true
    },
    isSummary: {
      type: Boolean,
      required: true
    },
    tasksInvoices: {
      required: true
    },
    actions: {
      required: true
    },
    invoiceToOverwrite: {
      type: Array,
      required: true
    },
    cancelledTasks: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    getActionLabel (value) {
      return this.actions.find(action => action.value === value).label
    }
  }
}
